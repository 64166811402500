<template>
  <v-app id='inspire'>
    <nav-bar />

    <v-main class='grey lighten-3'>
      <v-container>
        <v-row>
          <v-col>
            <v-sheet
              min-height='70vh'
              rounded='lg'
              class='pa-5'
            >
              <v-container style='text-align: center'>
                <iframe class='video-js'
                        src='https://player.twitch.tv/?channel=voicetv&parent=www.voicetv.co.th&parent=voicetv.co.th&parent=member.voicetv.co.th'
                        frameborder='0' allowfullscreen='true' scrolling='no' height='640' width='960'></iframe>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from '@/components/NavBar'

export default {
  components: {
    NavBar,
  },
}
</script>
